import React, {
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { Link } from 'gatsby';
import { Link as LinkI18N, useI18next } from 'gatsby-plugin-react-i18next';
import useShrinkableHeader from 'react-hooks-use-shrinkable-header';
import useMobileMenu from 'react-hooks-use-mobile-menu';
import classNames from 'classnames';

import {
  SERVICES,
  CONTACT,
  METHODOLOGIE,
  REALISATIONS,
  ABOUT_US,
  BLOG,
  HOME,
} from '@/referentials/routes';

import Logo from '@/assets/svg/logo.svg';
import Close from '@/assets/svg/close.svg';

import ThemeToggle from '../ThemeToggle';
import Content from '../Content';
import Button from '../Button';

interface MobileMenuProps {
  languages: string[];
  originalPath: string;
  language: string;
  links: {
    label: string;
    href: string;
  }[];
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  language,
  languages,
  originalPath,
  links,
  setMobileMenuOpen,
}) => {
  const [open, openMenu, closeMenu] = useMobileMenu();
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    setTimeout(() => setHidden(!open), open ? 0 : 200);
  }, [open]);

  useEffect(() => {
    setMobileMenuOpen(open);
  }, [open]);

  return (
    <>
      <div
        className={classNames(
          { 'z-50': !hidden },
          { '-z-10': hidden },
          { 'opacity-0': !open },
          'fixed inset-0 bg-mobileMenuBgClear dark:bg-mobileMenuBgDark z-50 opacity-1 transition-opacity',
        )}
      >
        <div className="absolute flex flex-col bottom-12 right-8 text-2xl font-bold items-end">
          <ul className="flex text-lg mb-8">
            {languages.map((lng, i) => (
              <li
                key={lng}
                className={classNames(
                  'px-3 transition-colors',
                  { 'text-primary': language === lng },
                  { 'hover:text-primaryLighter': language !== lng },
                  {
                    'border-r': i !== languages.length - 1,
                  },
                )}
              >
                <LinkI18N to={originalPath} language={lng}>
                  {lng.toUpperCase()}
                </LinkI18N>
              </li>
            ))}
          </ul>
          {links.map((link, i) => (
            <Link to={link.href} className="mb-6" key={i}>
              {link.label}
            </Link>
          ))}
          <Link to={CONTACT} className="mb-8">
            Contact
          </Link>
          <Close
            className="w-[48px] h-[48px] fill-primary"
            onClick={closeMenu}
          />
        </div>
      </div>
      {!open && (
        <Button
          className="xl:hidden fixed bottom-12 right-8 z-[5000]"
          onClick={openMenu}
        >
          MENU
        </Button>
      )}
    </>
  );
};

const Header: React.FC = () => {
  const shrinked: boolean = useShrinkableHeader(30);
  const { languages, originalPath, language, t } = useI18next();
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const links = useMemo(
    () => [
      {
        label: t('headerServices'),
        href: SERVICES,
      },
      {
        label: t('headerMethodology'),
        href: METHODOLOGIE,
      },
      {
        label: t('headerPortfolio'),
        href: REALISATIONS,
      },
      {
        label: t('headerAbout'),
        href: ABOUT_US,
      },
      {
        label: t('headerBlog'),
        href: BLOG,
      },
    ],
    [t],
  );
  const localeLink = (to) => {
    const key = 'en';

    if (typeof window !== 'undefined') {
      const { pathname } = window.location;

      if (pathname.includes(`/${key}/`)) {
        return `/${key}${to}`;
      }
      return to;
    }

    return `/${key}`;
  };

  return (
    <div className="overflow-hidden">
      <div
        className={classNames(
          'flex items-center fixed w-full bg-white dark:bg-mainDark transition-header z-[400] top-0',
          { 'xl:h-[140px] h-[140px]': !shrinked },
          {
            'xl:h-[100px] h-[84px] bg-transparentWhite dark:bg-transparentMainDark backdrop-blur-md dark:backdrop-brightness-50':
              shrinked,
          },
          {
            'bg-transparent dark:bg-transparent backdrop-filter-none dark:backdrop-filter-none':
              mobileMenuOpen,
          },
        )}
      >
        <Content className="flex flex-1 justify-between items-center">
          <div className="flex items-center xl:justify-start justify-between flex-1">
            <Link to={HOME}>
              <Logo className="h-[20px] xl:h-[32px] mr-10 dark:fill-white" />
            </Link>
            <ThemeToggle />
          </div>
          <div className="hidden xl:flex items-center">
            {links.map((link) => (
              <Link
                key={link.href}
                to={localeLink(link.href)}
                partiallyActive
                activeClassName="active"
                className="mr-12 text-base xl:text-lg font-bold underlineStyle"
              >
                {link.label}
              </Link>
            ))}
            <Link to={CONTACT}>
              <Button>{t('headerContact')}</Button>
            </Link>
          </div>
          <ul className="hidden xl:flex absolute top-3 right-3 text-sm">
            {languages.map((lng, i) => (
              <li
                key={lng}
                className={classNames(
                  'px-3 transition-colors',
                  { 'text-primary': language === lng },
                  { 'hover:text-primaryLighter': language !== lng },
                  {
                    'border-r': i !== languages.length - 1,
                  },
                )}
              >
                <LinkI18N to={originalPath} language={lng}>
                  {lng.toUpperCase()}
                </LinkI18N>
              </li>
            ))}
          </ul>
        </Content>
      </div>
      <MobileMenu
        languages={languages}
        originalPath={originalPath}
        language={language}
        links={links}
        setMobileMenuOpen={setMobileMenuOpen}
      />
    </div>
  );
};

export default Header;
