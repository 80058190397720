import React from 'react';
import classNames from 'classnames';

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  children,
  className = '',
  onClick = () => null,
}) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div
    className={classNames(
      'font-heading transition cursor-pointer rounded-full bg-mainDark lg:hover:bg-primary text-white hover:text-white py-[12px] px-[28px] font-bold',
      'dark:bg-white dark:text-black lg:hover:dark:bg-primary',
      'lg:py-[10px] lg:px-[25px] lg:text-lg lg:font-semibold lg:hover:-translate-y-[4%] lg:hover:shadow-button lg:hover:shadow-primary',
      className,
    )}
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    {children}
  </div>
);

export default Button;
