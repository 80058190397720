import React from 'react';
import classNames from 'classnames';

import Cookies from '../Cookies';
import SEO from '../SEO';
import Footer from '../Footer';
import Header from '../Header';
import Content from '../Content';
import FixedIcons from '../FixedIcons';

interface PropsLayout {
  title?: string;
  description: string;
  withIcons?: boolean;
  className?: string;
}

const Layout: React.FC<PropsLayout> = ({
  children,
  title,
  description,
  withIcons = false,
  className,
}) => (
  <div
    className={classNames(
      'bg-backgroundLight text-mainDark dark:bg-mainDark dark:text-backgroundLight transition-colors overflow-hidden z-10 min-h-screen',
      className,
    )}
  >
    <SEO title={title} description={description} />
    <Header />
    <Content>
      {withIcons && <FixedIcons />}
      {children}
    </Content>
    <Footer />
    <Cookies />
  </div>
);

export default Layout;
