import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';

import MouseEffectImg from '@/components/MouseEffectImg';
import classNames from 'classnames';

const values = [
  'top-[23%] left-[55%] w-[7%] rotate-[-12deg] -translate-x-1/2 -translate-y-1/2',
  'top-[85%] left-[40%] rotate-45 w-[6%] -translate-x-1/2 -translate-y-1/2',
  'top-[85%] right-[8%] rotate-[30deg] w-[9%]',
  'top-1/2 right-[23%] w-[8%] translate-x-1/2 -translate-y-1/2',
  'top-[34%] left-[20%] w-[8%] -rotate-[30deg] -translate-x-1/2',
  'top-[17%] right-[10%] w-[14%] rotate-[25deg] translate-x-1/2',
  'top-[15%] -left-[10px] w-[7%] rotate-[120deg]',
  'top-3/4 left-[5%] w-[7%] rotate-[-30deg]',
];

const FixedIcons = () => {
  const data = useStaticQuery(graphql`
    query {
      sun: file(relativePath: { eq: "sun.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 180, placeholder: BLURRED)
        }
      }
      glass: file(relativePath: { eq: "glass.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 180, placeholder: BLURRED)
        }
      }
      heart: file(relativePath: { eq: "heart.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 180, placeholder: BLURRED)
        }
      }
      fire: file(relativePath: { eq: "fire.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 180, placeholder: BLURRED)
        }
      }
      music: file(relativePath: { eq: "music.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 180, placeholder: BLURRED)
        }
      }
      rocket: file(relativePath: { eq: "rocket.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 180, placeholder: BLURRED)
        }
      }
      trophy: file(relativePath: { eq: "trophy.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 180, placeholder: BLURRED)
        }
      }
      like: file(relativePath: { eq: "like.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 180, placeholder: BLURRED)
        }
      }
    }
  `);

  const unshuffled: ImageDataLike[] = Object.values(data);

  // J'aime bien perso le fait de les shuffle, à voir avec le seigneur des pinceaux
  // const shuffled = unshuffled
  //   .map((value) => ({ value, sort: Math.random() }))
  //   .sort((a, b) => a.sort - b.sort)
  //   .map(({ value }) => value);

  return (
    <div className="relative hidden lg:block">
      {unshuffled.map((obj, i) => (
        <div className={classNames('fixed', values[i])} key={i}>
          <MouseEffectImg image={getImage(obj)} alt="setting icon" />
        </div>
      ))}
    </div>
  );
};

export default FixedIcons;
