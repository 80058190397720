import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { COOKIES } from '@/referentials/routes';

const Cookies = () => {
  const [cookiesConsent, setCookiesConsent] = useState(true);

  useEffect(() => {
    setCookiesConsent(
      typeof window !== 'undefined'
        ? !!document?.cookie.match(/^(.*;)?\s*cookiesConsent\s*=\s*[^;]+(.*)?$/)
        : true,
    );
  }, []);

  const handleClickCookies = useCallback(() => {
    if (typeof window !== 'undefined') document.cookie = 'cookiesConsent=true';
    setCookiesConsent(true);
  }, []);

  if (cookiesConsent) return null;

  return (
    <div
      className={classNames(
        'fixed bottom-0 right-0 md:right-[14px] left-0 px-12 py-[25px] text-sm flex gap-4 items-center bg-white/50 z-[9999] backdrop-blur-md shadow-lg text-mainDark',
        'md:bottom-[30px] md:left-auto md:max-w-[645px] md:px-[40px] md:rounded-main md:text-lg md:gap-8',
      )}
    >
      <div className="hidden md:block text-[80px]">🍪</div>
      <div>
        Nous utilisons des <Link to={COOKIES}>cookies</Link> pour une expérience
        optimale. En continuant la navigation, vous acceptez leur utilisation.
      </div>
      <button
        type="button"
        onClick={handleClickCookies}
        className="bg-white px-[26px] py-[12px] rounded-full h-fit hover:shadow-md"
      >
        OK
      </button>
    </div>
  );
};

export default Cookies;
