import React from 'react';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';

import Switch from '../Switch';

export default function ThemeToggle() {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <Switch
          name="darkMode"
          onChange={(e) => toggleTheme(e.target.checked ? 'light' : 'dark')}
          value={theme === 'light'}
        />
      )}
    </ThemeToggler>
  );
}
