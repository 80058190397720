import React from 'react';
import classNames from 'classnames';

import MoonIcon from '@/assets/svg/moon.svg';
import SunIcon from '@/assets/svg/sun.svg';

export interface SwitchProps {
  name: string;
  onChange: (...event: any[]) => void;
  value: boolean;
  disabled?: boolean;
  className?: string;
  labels?: {
    left: string;
    right: string;
  };
}

const Switch: React.FC<SwitchProps> = ({
  name,
  onChange,
  value,
  disabled,
  labels,
  className = '',
}) => {
  return (
    <div className="flex items-center">
      {labels && <div className="font-normal mr-3 text-l">{labels.left}</div>}
      <label
        className={classNames(
          'relative',
          'inline-block',
          'w-[64px]',
          'h-[32px]',
          { 'opacity-50': disabled },
          className,
        )}
      >
        <input
          disabled={disabled}
          type="checkbox"
          data-testid={name}
          name={name}
          onChange={onChange}
          defaultChecked={value}
          className="sr-only"
        />
        <span
          className={classNames(
            'absolute',
            'top-0',
            'bottom-0',
            'left-0',
            'right-0',
            'rounded-full',
            'cursor-pointer',
            value ? 'bg-mainDark' : 'bg-primary',
            'transition-colors',
            'before:absolute',
            'before:content-[""]',
            'before:h-[24px]',
            'before:w-[24px]',
            'before:bottom-[4px]',
            'before:left-[5px]',
            'before:bg-white',
            'before:rounded-full',
            'before:transition-transform',
            'before:shadow-md',
            value && 'before:translate-x-[29px]',
          )}
        />
        <span
          className={classNames(
            'absolute',
            'top-1/2',
            'left-[12px]',
            '-translate-y-1/2',
            'cursor-pointer',
          )}
        >
          <SunIcon width={16} height={16} fill="white" />
        </span>
        <span
          className={classNames(
            'absolute',
            'top-1/2',
            'right-[12px]',
            '-translate-y-1/2',
            'cursor-pointer',
          )}
        >
          <MoonIcon width={16} height={16} fill="white" />
        </span>
      </label>
      {labels && <div className="font-normal ml-3 text-l">{labels.right}</div>}
    </div>
  );
};

export default Switch;
