import React, { useEffect, useRef, useState } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import classNames from 'classnames';

export interface MouseEffectImgProps {
  image?: IGatsbyImageData;
  alt?: string;
  className?: string;
}

const MouseEffectImg: React.FC<MouseEffectImgProps> = ({
  image,
  alt,
  children,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    const wSize = window?.innerWidth;
    const hSize = window?.innerHeight;

    const onMouseMove = (e: MouseEvent) => {
      const pos = ref?.current?.getBoundingClientRect();
      const midImage = { x: pos.width / 2 + pos.x, y: pos.height / 2 + pos.y };
      const { clientX, clientY } = e;
      setX(((midImage.x - clientX) / wSize) * 20);
      setY(((midImage.y - clientY) / hSize) * 20);
    };

    // eslint-disable-next-line react/function-component-definition
    if (wSize < 1044) return () => null;

    document.addEventListener('mousemove', onMouseMove);

    return () => {
      if (wSize > 1044) document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return (
    <div
      ref={ref}
      style={{ transform: `translate3d(${x}px, ${y}px, 0px)` }}
      className={classNames('transition-transform ease-linear', className)}
    >
      {image ? <GatsbyImage image={image} alt={alt} /> : children}
    </div>
  );
};

export default MouseEffectImg;
