import classNames from 'classnames';
import React from 'react';

interface ContentProps {
  children?: React.ReactNode;
  className?: string;
}

const Content: React.FC<ContentProps> = ({ children, className }) => (
  <div className={classNames('contentM', className)}>{children}</div>
);

export default Content;
