import React from 'react';
import { Helmet } from 'react-helmet';

interface PropsSEO {
  title?: string;
  description: string;
}

const SEO: React.FC<PropsSEO> = ({ title, description }) => {
  const ccTitle = 'charrette.club';
  const finalTitle = title ? `${title} | ${ccTitle}` : ccTitle;

  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="48x48"
        href="/icons/favfavicon-48x48.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="/icons/favicon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="96x96"
        href="/icons/favicon-96x96.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/icons/favicon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/icons/favicon-192x192.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="256x256"
        href="/icons/favicon-256x256.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="384x384"
        href="/icons/favicon-384x384.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="512x512"
        href="/icons/favicon-512x512.png"
      />
      <title>{finalTitle}</title>
      <meta name="description" content={description} />

      <meta name="og:title" content={finalTitle} />
      <meta name="og:description" content={description} />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content="Charrette.club" />
    </Helmet>
  );
};

export default SEO;
