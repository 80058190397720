export const HOME = '/';
export const COOKIES = '/cookies';
export const SERVICES = '/services';
export const METHODOLOGIE = '/methodologie';
export const REALISATIONS = '/realisations';
export const ABOUT_US = '/a-propos';
export const CONTACT = '/contact';
export const CLUB = '/club';
export const CONSTRUCTECH = '/constructech';
export const LEGAL = '/legal';

export const BLOG = 'https://leblognotes.charrette.club/';
export const SHAPE_DIVER = 'https://shapediver.com/';
export const IMPULSE = 'https://impulse-partners.com/';

export const ARTELINK_PROJECT = '/realisations/artelink';
export const EDF_PROJECT = '/realisations/edf';
export const LAFRAISE_PROJECT = '/realisations/lafraise';
export const APV_PROJECT = '/realisations/apv';
export const GENIE_VISION_PROJECT = '/realisations/genievision';
export const NAVYS_PROJECT = '/realisations/navys';
export const SETEC_PROJECT = '/realisations/setec';
export const LMSG_PROJECT = '/realisations/la-maison-saint-gobain';
export const MM2_PROJECT = '/realisations/mon-maitre-carre';
export const HARMONIELLES_PROJECT = '/realisations/harmonielles';
export const LAPOSTE_PROJECT = '/realisations/la-poste';

export const SERVICE_DEV = '/services/developpement-back-front-end';
export const SERVICE_DATA = '/services/data-science';
export const SERVICE_TRANSITION = '/services/transition-digitale';
export const SERVICE_UXUI = '/services/ux-ui';
export const SERVICE_GROWTH = '/services/growth-hacking';
