import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import {
  SERVICES,
  METHODOLOGIE,
  REALISATIONS,
  ABOUT_US,
  CONTACT,
  CLUB,
  CONSTRUCTECH,
  LEGAL,
  SHAPE_DIVER,
  IMPULSE,
  BLOG,
} from '@/referentials/routes';

import Logo from '@/assets/svg/logo.svg';

import LogoImpulseLight from '@/assets/img/logos-clients/light-mode/impulse-partners.svg';
import LogoImpulseDark from '@/assets/img/logos-clients/dark-mode/impulse-partners.svg';

const footerLinks = [
  {
    title: 'footerCompanyName',
    links: [
      {
        label: 'footerServices',
        link: SERVICES,
      },
      {
        label: 'footerMethodologie',
        link: METHODOLOGIE,
      },
      {
        label: 'footerRealisations',
        link: REALISATIONS,
      },
      {
        label: 'footerAvoutUs',
        link: ABOUT_US,
      },
      {
        label: 'footerContact',
        link: CONTACT,
      },
    ],
  },
  {
    title: 'footerUsefullLinks',
    links: [
      {
        label: 'footerClub',
        link: CLUB,
      },
      {
        label: 'footerConstructech',
        link: CONSTRUCTECH,
      },
      {
        label: 'footerBlog',
        link: BLOG,
      },
      {
        label: 'footerLegal',
        link: LEGAL,
      },
    ],
  },
];

const SectionTitle: React.FC = ({ children }) => (
  <h2 className="mb-2 lg:mb-3 text-xl lg:text-2xl leading-4">{children}</h2>
);

interface PropsFooterLink {
  to: string;
}

const FooterLink: React.FC<PropsFooterLink> = ({ children, to }) => (
  <Link to={to} className="mt-3 lg:mt-6 text-sm lg:text-xl">
    {children}
  </Link>
);

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white dark:bg-mainDark relative">
      <div className="contentM mt-[150px] lg:mt-[300px] pb-[150px] lg:pb-[90px]">
        <div className="w-full h-2 bg-primary rounded mb-[45px] lg:mb-[90px]" />
        <div className="flex flex-col lg:flex-row justify-between text-mainDark dark:text-white">
          <div className="hidden lg:inline-block">
            <Logo className="h-7 w-auto fill-mainDark dark:fill-white" />
            <p className="text-base mt-4">© 2024</p>
          </div>

          <div className="flex flex-row justify-between lg:justify-evenly w-full mb-8">
            {footerLinks.map((section, i) => (
              <div className="w-6/12 lg:w-auto" key={i}>
                <SectionTitle>{t(section.title)}</SectionTitle>

                <div className="flex flex-col">
                  {section.links.map((elem, k) => (
                    <FooterLink to={elem.link} key={k}>
                      <p className="w-fit underlineStyle">{t(elem.label)}</p>
                    </FooterLink>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-row justify-between">
            <div className="inline-block lg:hidden w-6/12">
              <Logo className="h-auto w-[125px] fill-mainDark dark:fill-white" />
              <p className="text-sm mt-3">© 2024</p>
            </div>

            <div className="mr-0 lg:mr-[50px] w-6/12 lg:w-auto">
              <SectionTitle>{t('footerPartners')}</SectionTitle>

              <div className="flex flex-col">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="mt-3 lg:mt-6"
                  href={IMPULSE}
                >
                  <LogoImpulseLight
                    alt="Impulse partners Logo"
                    height={20}
                    className="inline-block dark:hidden"
                  />
                  <LogoImpulseDark
                    alt="Impulse partners Logo"
                    height={20}
                    className="hidden dark:inline-block"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="mt-3 lg:mt-6"
                  href={SHAPE_DIVER}
                >
                  <StaticImage
                    src="../../assets/img/shapediver_black.png"
                    alt="Shape Diver Logo"
                    height={20}
                    className="inline-block dark:hidden"
                  />
                  <StaticImage
                    src="../../assets/img/shapediver.png"
                    alt="Shape Diver Logo"
                    height={20}
                    className="hidden dark:inline-block"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
